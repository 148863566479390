import React, { useRef, useState, useEffect, useContext } from "react";
import "./TimerSection.scss";
import { TextDiv, TimerInput } from "../styledComponents";
import useSound from "use-sound";
import sec8mp3 from "../../../assets/sounds/8sec.mp3";
import sec12mp3 from "../../../assets/sounds/12sec.mp3";
import Lock from "../../../assets/images/timer/lock.png";
import Copy from "../../../assets/images/timer/copy.png";
import Refresh from "../../../assets/images/timer/refresh.png";
import {
  extractCentiseconds,
  extractMinutes,
  extractSeconds,
} from "./TimerLogic";
import { generateScramble } from "../../../utils/functions/scrambleFunctions";
import { ReserveContext } from "../../../App";
import { submitSolve } from "../../../apis/solves";
import { useIsMobile } from "../../../utils/functions/CustomHooks";
import { ThemeContext } from "../../../context";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { MdLockOpen, MdOutlineLock } from "react-icons/md";
import { LuCopy, LuRefreshCcw } from "react-icons/lu";
import { formatData } from "../../../utils/functions/csTimer";
const TimerSection = ({
  allStyles,
  setScramble,
  scramble,
  refresh,
  setRefresh,
  cubeType,
  disableCubeType,
  setDisableCubeType,
  svg,
  setSvg,
  sessionData,
  setSessionData,
  isTimer,
  setIsTimer,
  isOpen,
  setIsOpen,
  lockScramble,
  setLockScramble,
  lockIcon,
  setLockIcon,
}) => {
  const isMobile = useIsMobile();
  const timerDisplayRef = useRef(false);
  const [displayTime, setDisplayTime] = useState(0);
  const [isCopied, setIsCopied] = useState(false);
  const [scrambleRefresh, setScrambleRefresh] = useState(false);
  const plus2autoRef = useRef(false);
  // const inspRunningRef = useRef(false);
  const infiniteLoopTouchDown = useRef(false);
  const {
    scrambleFontSize,
    TimerFontSize,
    ManualTime,
    TimerDisplay,
    TimerFormat,
    showFooter,
    Inspection,
    VoiceAlert,
    isFocus,
    dispCNS,
    setDispCNS,
    hideTimer, setHideTimer,
    solveSubmit, setSolveSubmit, inspIntRef, inspRunningRef, guide, setguide
  } = useContext(ReserveContext);

  //const [play8Sec] = useSound(sec8mp3, {volume:1})
  const play8sec = new Audio(sec8mp3);
  const play12sec = new Audio(sec12mp3);
  var updateReserve = useContext(ReserveContext).updateReserve;
  const [inspColor, setInspColor] = useState("white");
  var reserve = useContext(ReserveContext).reserve;
  const { themeName } = useContext(ThemeContext);
  // const [timerStop, setTimerStop] = useState(false);
  const [timerInput, setTimerInput] = useState("");
  var timerInterval;
  const inspColorTimeoutRef = useRef(null);
  const [tI, setTI] = useState(null);

  const [inspCd, setInspCd] = useState(16);
  const [inspDnf, setInspDnf] = useState(false);
  const timerInspectionRef = useRef(false);
  const play8secRef = useRef(null);
  const play12secRef = useRef(null);

  useEffect(() => {
    play8secRef.current = new Audio(sec8mp3);
    play12secRef.current = new Audio(sec12mp3);

    // This function will be called when the component mounts
    const initializeAudio = async () => {
      try {
        // Try to play a silent audio buffer to initiate the audio context
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const buffer = audioContext.createBuffer(1, 1, 22050);
        const source = audioContext.createBufferSource();
        source.buffer = buffer;
        source.connect(audioContext.destination);
        source.start(0);
        await source.startPromiseNode();
        source.stop(0);
      } catch (error) {
        console.error("Failed to initialize audio context:", error);
      }
    };

    initializeAudio();

  }, []);

  // useEffect(() => {
  //   if (guide) {
  //     setguide(true)
  //   }else{
  //     setguide(false)
  //   }
  // },[guide])

  // const [hideTimer, setHideTimer] = useState(false)
  // const [solveSubmit, setSolveSubmit] = useState(false)
  /**
   * The function `touchUp` in JavaScript React handles various conditions related to a timer,
   * inspection, and solving a cube.
   * @returns The `touchUp` function returns different values based on certain conditions:
   */
  const touchUp = () => {
    if (!dispCNS) {
      // hide timer when inspection is off
      if (TimerDisplay && !Inspection) {
        // timerDisplayRef.current.classList.toggle("hidden");
        setHideTimer(true)
      }

      // hide timer when inspection is on
      // function hideTimerDisplayWithInspection() {
      //   if (
      //     inspRunningRef.current &&
      //     !timerDisplayRef.current.classList.contains("hidden") &&
      //     timerInspectionRef.current.classList.contains("hidden")
      //   ) {
      //     timerDisplayRef.current.classList.add("hidden");
      //     timerInspectionRef.current.classList.remove("hidden");
      //     return;
      //   }
      //   if (
      //     timerDisplayRef.current.classList.contains("hidden") &&
      //     !timerInspectionRef.current.classList.contains("hidden")
      //   ) {
      //     timerInspectionRef.current.classList.add("hidden");
      //     return;
      //   }
      //   if (
      //     !inspRunningRef.current &&
      //     timerDisplayRef.current.classList.contains("hidden") &&
      //     timerInspectionRef.current.classList.contains("hidden")
      //   ) {
      //     timerDisplayRef.current.classList.remove("hidden");
      //     return;
      //   }
      // }
      function hideTimerDisplayWithInspection() {
        setHideTimer(true)
      }

      if (TimerDisplay && Inspection) {
        hideTimerDisplayWithInspection();

      }

      setInspColor((prevInspColor) => {
        if (inspRunningRef.current && prevInspColor == "red") {
          clearTimeout(inspColorTimeoutRef.current);
          return "white";
        }
        if (inspRunningRef.current && prevInspColor == "green") {
          clearTimeout(inspColorTimeoutRef.current);
          clearInterval(inspIntRef.current);
          inspRunningRef.current = false;
          let start = Date.now();

          setIsTimer(true);
          timerInterval = setInterval(() => {
            let now = Date.now();
            setDisplayTime(now - start);
          }, 1);
          setTI(timerInterval);
          // setHideTimer(false)
          return "white";
        }
        return "white";
      });

      // console.log(inspRunningRef.current);
      if (inspRunningRef.current && !isTimer) {
        return;
      }

      if (isTimer) {
        // setScramble(generateScramble(cubeType));
        setRefresh(!refresh);

        setIsTimer(false);

        setDisplayTime((prevDisplayTime) => {
          const quickCall = async () => {
            let body = {
              time: prevDisplayTime,
              cubeType: cubeType,
              scramble: scramble,
              notes: " ",
              sessionId: sessionData.sessionId,
              dateTime: new Date().toString(),
              plus2auto: plus2autoRef.current,
              dnfAuto: inspDnf,
            };
            setInspDnf(false);
            setSolveSubmit(true)
            let solve = (await submitSolve(body)).solve;
            updateReserve((reserve) => {
              if (!reserve.solves) reserve.solves = [];
              reserve.solves.push(solve);
            });
            plus2autoRef.current = false;
          };
          quickCall();
          return prevDisplayTime; // Return the previous value
        });

        return;
      }

      inspRunningRef.current = false;
      if (Inspection) {
        return;
      } else {
        let start = Date.now();

        setIsTimer(true);
        timerInterval = setInterval(() => {
          let now = Date.now();
          setDisplayTime(now - start);
        }, 1);
        setTI(timerInterval);
      }
    }
  };

  /**
   * The `touchDown` function in JavaScript React handles various conditions related to an inspection
   * timer, including countdown, voice alerts, and automatic penalties.
   * @returns The `touchDown` function returns nothing explicitly.
   */
  const touchDown = () => {
    setHideTimer(false)
    setSolveSubmit(false)
    if (!dispCNS) {
      if (inspRunningRef.current) {
        // clearInterval(inspIntRef.current);
        // setInspCd(16);
        // inspRunningRef.current = false;
        setInspColor((prevInspColor) => {
          if (prevInspColor == "red") {
            return "red";
          }
          if (prevInspColor == "green") {
            return "green";
          }
          // console.log(prevInspColor);
          if (prevInspColor == "white") {
            inspColorTimeoutRef.current = setTimeout(() => {
              setInspColor("green");
            }, 500);
            return "red";
          }
        });
        return;
      }

      if (Inspection && !inspRunningRef.current && !isTimer) {
        setInspCd((prevInspCd) => {
          inspRunningRef.current = true;
          return 0;
        });
        inspIntRef.current = setInterval(() => {
          setInspCd((prevInspCd) => {
            if (VoiceAlert) {
              if (prevInspCd == 7) {
                play8secRef.current.play();
              }
              if (prevInspCd == 11) {
                play12secRef.current.play();
              }
            }
            if (prevInspCd == 14) {
              plus2autoRef.current = true;
            }
            if (prevInspCd == 16) {
              clearInterval(inspIntRef.current);
              setInspDnf(true);
              // inspRunningRef.current = false;
              // const quickCall = async () => {
              //   let body = {
              //     time: 3600000,
              //     cubeType: cubeType,
              //     scramble: scramble,
              //     notes: " ",
              //     sessionId: sessionData.sessionId,
              //     dateTime: new Date().toString(),
              //     dnfAuto: true,
              //     plus2auto: plus2autoRef.current,
              //   };
              //   let solve = (await submitSolve(body)).solve;
              //   updateReserve((reserve) => {
              //     if (!reserve.solves) reserve.solves = [];
              //     reserve.solves.push(solve);
              //   });
              //   plus2autoRef.current = false;
              // };
              // quickCall();
              return prevInspCd;
            }
            return prevInspCd + 1;
          });
        }, 1000);
      }

      clearInterval(tI);
      clearInterval(timerInterval);
    }
  };

  // When the key is pressed
  const spacedown = (event) => {
    // setHideTimer(false)
    if (event.code != "Space" || guide) {
      return;
    }

    if (!dispCNS) {
      event.preventDefault();
      touchDown();
    }
  };
  // When the key is released
  const spaceup = (event) => {
    // event.preventDefault();
    setHideTimer(false)
    if (event.code != "Space" || guide) {
      if (isTimer) {
        clearInterval(tI);
        clearInterval(timerInterval);
        setRefresh(!refresh);
        // setIsTimer(false);
        setDisplayTime((prevDisplayTime) => {
          const quickCall = async () => {
            let body = {
              time: prevDisplayTime,
              cubeType: cubeType,
              scramble: scramble,
              notes: " ",
              sessionId: sessionData.sessionId,
              dateTime: new Date().toString(),
              plus2auto: plus2autoRef.current,
              dnfAuto: inspDnf,
            };
            setInspDnf(false);
            let solve = (await submitSolve(body)).solve;
            updateReserve((reserve) => {
              if (!reserve.solves) reserve.solves = [];
              reserve.solves.push(solve);
            });
            plus2autoRef.current = false;
          };
          quickCall();
          return prevDisplayTime; // Return the previous value
        });
      }

      return;
    }
    
    if (!dispCNS) {
      event.preventDefault();
      touchUp();
    }
  };

  const textRef = useRef(null);
  // const [lockScramble, setLockScramble] = useState(false);
  

  useEffect(() => {
    if (!ManualTime) {
      window.addEventListener("keydown", spacedown);
      window.addEventListener("keyup", spaceup);

      let check = localStorage.getItem("lockScramble");
      if (check) {
        setLockScramble(true);
      }

      return () => {
        window.removeEventListener("keydown", spacedown);
        window.removeEventListener("keyup", spaceup);
      };
    }
  }, [guide, refresh, cubeType, sessionData, isTimer, dispCNS]);

  // copying scramble to clipboard
  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(scramble);
      if (!isCopied) {
        setIsCopied(true);
        toast.success("Copied to Clipboard .", { autoClose: 1000, className: "copy-toast" });
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      }
    } catch (error) {
      toast.error("Failed to copy text to clipboard.", { autoClose: 1000, className: "copy-toast" });
    }
  };

  // Locking scramble in storage
  const LockScrambleInStorage = () => {
    let check = localStorage.getItem("lockScramble");
    let checkCubeTy = localStorage.getItem("lockCubeType");
    if (check) {
      toast.success("Scramble Unlocked!", { autoClose: 1000, className: "copy-toast" });
      if (checkCubeTy == cubeType) {
        localStorage.removeItem("lockScramble");
        localStorage.removeItem("lockCubeType");
        localStorage.removeItem("svg");
        setLockScramble(false);
        setLockIcon(false);
      } else {
        localStorage.setItem("lockScramble", scramble);
        localStorage.setItem("lockCubeType", cubeType);
        localStorage.setItem("svg", svg);
        setLockScramble(true);
        setLockIcon(true);
      }
    } else {
      toast.success("Scramble Locked!", { autoClose: 1000, className: "copy-toast" });
      localStorage.setItem("lockScramble", scramble);
      localStorage.setItem("lockCubeType", cubeType);
      localStorage.setItem("svg", svg);
      setLockScramble(true);
      setLockIcon(true);
    }
  };

  // handle key press when manual time input is enabled
  function handleKeyPress(event) {
    if (event.code === "Enter") {
      // if (TimerFormat === "ss.XX") {
      //   const seconds = timerInput.split(".")[0];
      //   const milliseconds = timerInput.split(".")[1];
      //   const secondsInMilliseconds = timerInput * 1000;
      //   const totalMilliseconds = secondsInMilliseconds;
      //   setDisplayTime(totalMilliseconds);
      //   setTI(totalMilliseconds);

      //   if (seconds >= 60) {
      //     toast.error(`Seconds cannot be greater than 60.`);
      //     return;
      //   }
      //   if (milliseconds > 99) {
      //     toast.error(`Milliseconds cannot be greater than 99.`);
      //     return;
      //   }
      // }
      // } else if (TimerFormat === "mm:ss.XX") {
      //   const minutes = timerInput.split(":")[0];
      //   const seconds = timerInput.split(":")[1].split(".")[0];
      //   const milliseconds = timerInput.split(":")[1].split(".")[1];
      //   const minutesInMilliseconds = minutes * 60;
      //   const totalMilliseconds = (parseInt(minutesInMilliseconds) + parseInt(seconds) + '.' + milliseconds) * 1000;
      //   setDisplayTime(totalMilliseconds);

      //   if (minutes >= 60) {
      //     toast.error(`Minutes cannot be greater than 60.`);
      //     return;
      //   }
      //   if (seconds >= 60) {
      //     toast.error(`Seconds cannot be greater than 60.`);
      //     return;
      //   }
      //   if (milliseconds > 99) {
      //     toast.error(`Milliseconds cannot be greater than 99.`);
      //     return;
      //   }
      // } else if (TimerFormat === "hh:mm:ss.XX") {
      //   const hours = timerInput.split(":")[0];
      //   const minutes = timerInput.split(":")[1];
      //   const seconds = timerInput.split(":")[2].split(".")[0];
      //   const milliseconds = timerInput.split(":")[2].split(".")[1];
      //   const hoursInSeconds = hours * 3600;
      //   const minutesInSeconds = minutes * 60;
      //   const secondsInMilliseconds = ((parseInt(hoursInSeconds) + parseInt(minutesInSeconds) + parseInt(seconds)) + '.' + milliseconds) * 1000;
      //   const totalMilliseconds = secondsInMilliseconds
      //   setDisplayTime(totalMilliseconds);
      //   if (hours >= 24) {
      //     toast.error(`Hours cannot be greater than 24.`);
      //     return;
      //   }
      //   if (minutes >= 60) {
      //     toast.error(`Minutes cannot be greater than 60.`);
      //     return;
      //   }
      //   if (seconds >= 60) {
      //     toast.error(`Seconds cannot be greater than 60.`);
      //     return;
      //   }
      //   if (milliseconds > 99) {
      //     toast.error(`Milliseconds cannot be greater than 99.`);
      //     return;
      //   }
      // }

      // if (TimerFormat === "ss.XX"){
      //   setDisplayTime(timerInput)
      // }

      let inputValue = timerInput.trim();

      // Remove any non-numeric characters from the input
      inputValue = timerInput.replace(/[^\d]/g, "");

      const inputLength = inputValue.length;
      // Check the length of the input to determine the formatting
      if (inputLength === 1) {
        // If there's only one digit, pad it with leading zeros and append ".00"
        inputValue = `00.0${inputValue}`;
        const totalMilliseconds1 = inputValue * 1000;
        setDisplayTime(totalMilliseconds1);
      } else if (inputLength === 2) {
        // If there are two digits, pad the first digit with a leading zero and append ".00"
        inputValue = `00.${inputValue}`;
        const totalMilliseconds2 = inputValue * 1000;
        setDisplayTime(totalMilliseconds2);
      } else if (inputLength === 3) {
        // If there are three digits, insert a dot before the last two digits
        inputValue = `0${inputValue.slice(0, 1)}.${inputValue.slice(1)}`;
        const totalMilliseconds3 = inputValue * 1000;
        setDisplayTime(totalMilliseconds3);
      } else if (inputLength === 4) {
        // If there are four digits, insert a dot before the last two digits
        inputValue = `${inputValue.slice(0, 2)}.${inputValue.slice(2)}`;
        const totalMilliseconds4 = inputValue * 1000;
        setDisplayTime(totalMilliseconds4);
      } else if (inputLength === 5) {
        // If there are three digits, insert a dot before the last two digits
        inputValue = `0${inputValue.slice(0, 1)}:${inputValue.slice(
          1,
          3
        )}.${inputValue.slice(3, 5)}`;
        const minutes = inputValue.split(":")[0];
        const seconds = inputValue.split(":")[1].split(".")[0];
        const milliseconds = inputValue.split(":")[1].split(".")[1];
        const minutesInMilliseconds = minutes * 60;
        const totalMilliseconds5 =
          (parseInt(minutesInMilliseconds) +
            parseInt(seconds) +
            "." +
            milliseconds) *
          1000;
        setDisplayTime(totalMilliseconds5);
      } else if (inputLength === 6) {
        // If there are four digits, insert a dot before the last two digits
        inputValue = `${inputValue.slice(0, 2)}:${inputValue.slice(
          2,
          4
        )}.${inputValue.slice(4, 6)}`;
        const minutes = inputValue.split(":")[0];
        const seconds = inputValue.split(":")[1].split(".")[0];
        const milliseconds = inputValue.split(":")[1].split(".")[1];
        const minutesInMilliseconds = minutes * 60;
        const totalMilliseconds6 =
          (parseInt(minutesInMilliseconds) +
            parseInt(seconds) +
            "." +
            milliseconds) *
          1000;
        setDisplayTime(totalMilliseconds6);
      } else if (inputLength === 7) {
        // If there are four digits, insert a dot before the last two digits
        inputValue = `0${inputValue.slice(0, 1)}:${inputValue.slice(
          1,
          3
        )}:${inputValue.slice(3, 5)}.${inputValue.slice(5, 7)}`;
        const hours = inputValue.split(":")[0];
        const minutes = inputValue.split(":")[1];
        const seconds = inputValue.split(":")[2].split(".")[0];
        const milliseconds = inputValue.split(":")[2].split(".")[1];
        const hoursInSeconds = hours * 3600;
        const minutesInMilliseconds = minutes * 60;
        const totalMilliseconds6 =
          (parseInt(hoursInSeconds) +
            parseInt(minutesInMilliseconds) +
            parseInt(seconds) +
            "." +
            milliseconds) *
          1000;
        setDisplayTime(totalMilliseconds6);
      } else if (inputLength === 8) {
        // If there are four digits, insert a dot before the last two digits
        inputValue = `${inputValue.slice(0, 2)}:${inputValue.slice(
          2,
          4
        )}:${inputValue.slice(4, 6)}.${inputValue.slice(6, 8)}`;
        const hours = inputValue.split(":")[0];
        const minutes = inputValue.split(":")[1];
        const seconds = inputValue.split(":")[2].split(".")[0];
        const milliseconds = inputValue.split(":")[2].split(".")[1];
        const hoursInSeconds = hours * 3600;
        const minutesInMilliseconds = minutes * 60;
        const totalMilliseconds6 =
          (parseInt(hoursInSeconds) +
            parseInt(minutesInMilliseconds) +
            parseInt(seconds) +
            "." +
            milliseconds) *
          1000;
        setDisplayTime(totalMilliseconds6);
      }

      setDisplayTime((prevDisplayTime) => {
        const quickCall = async () => {
          let body = {
            time: prevDisplayTime,
            cubeType: cubeType,
            scramble: scramble,
            notes: " ",
            sessionId: sessionData.sessionId,
            dateTime: new Date().toString(),
          };
          let solve = (await submitSolve(body)).solve;
          updateReserve((reserve) => {
            if (!reserve.solves) reserve.solves = [];
            reserve.solves.push(solve);
          });
        };
        quickCall();
        return prevDisplayTime; // Return the previous value
      });
      setTimerInput("");
      setRefresh(!refresh);
      // setIsTimer(true)
      // touchUp();
    }
  }

  // handle input change for manual time input
  const handleInputChange = (e) => {
    const { value } = e.target;
    if (value.includes(" ")) {
      toast.error("Spaces are not allowed in the timer input.");
      return;
    }

    const numbersRegex = /^[0-9]+$/;

    if (!numbersRegex.test(value)) {
      toast.error("Only numbers are allowed in the timer input.");
      return;
    }
    // Remove non-numeric characters
    // let formattedValue = value.replace(/[^\d.]/g, "");

    // let delimiter = ":";
    // Handle backspace deletion of delimiter
    // if (e.key === "Backspace" && value.charAt(value.length) === delimiter) {
    //   formattedValue = formattedValue.slice(0, value.length - 1);
    // }

    // putting automatic diameter
    // if (TimerFormat === "ss.XX") {
    //   if (formattedValue.length === 2) {
    //     formattedValue =
    //       formattedValue.substring(0, 2) + "." + formattedValue.substring(2);
    //   }
    // } else if (TimerFormat === "mm:ss.XX") {
    //   if (formattedValue.length >= 2) {
    //     formattedValue =
    //       formattedValue.substring(0, 2) +
    //       delimiter +
    //       formattedValue.substring(2);
    //   }
    //   if (formattedValue.length === 5) {
    //     formattedValue =
    //       formattedValue.substring(0, 6) + "." + formattedValue.substring(6);
    //   }
    // } else if (TimerFormat === "hh:mm:ss.XX") {
    //   if (formattedValue.length >= 2) {
    //     formattedValue =
    //       formattedValue.substring(0, 2) +
    //       delimiter +
    //       formattedValue.substring(2);
    //   }
    //   if (formattedValue.length >= 5) {
    //     formattedValue =
    //       formattedValue.substring(0, 5) +
    //       delimiter +
    //       formattedValue.substring(5);
    //   }
    //   if (formattedValue.length === 8) {
    //     formattedValue =
    //       formattedValue.substring(0, 9) + "." + formattedValue.substring(9);
    //   }
    // }

    // Handle backspace key
    // if (e.nativeEvent.inputType === "deleteContentBackward") {
    //   const cursorPosition = e.target.selectionStart;
    //   if (cursorPosition > 0 && (formattedValue[cursorPosition] === delimiter || formattedValue[cursorPosition] === ".")) {
    //     formattedValue =
    //       formattedValue.substring(0, cursorPosition)
    //   }
    // }

    setTimerInput(value);
  };

  const keypressDispatch = () => {
    window.dispatchEvent(new KeyboardEvent("keydown", { key: "a" }));
  };
  const keyupDispatch = () => {
    window.dispatchEvent(new KeyboardEvent("keyup", { key: "a" }));
  };

  const scrambleStrings = ['DR DL UL', 'DR DL', 'DR UL', 'DL UL', 'DR', 'DL', 'UL']

  const checkScrString = (scr, scrString) => {
    return scrString.some(str => scr.endsWith(str));
  };

  const scrString = (scr, scrString) => {
    return scrString.find(str => scr.endsWith(str) || '');
  };

  const removeSubstring = (scr, substr) => {
    const index = scr.lastIndexOf(substr);
    if (index === -1) {
      return scr; // Substring not found, return original string
    }
    return scr.slice(0, index);
  };

  const splitScramble = (value) => {
    // Split on U or U' and include them back in the result
    return value.split(/(U\s|U'\s)/).reduce((result, part) => {
      if (part) {
        result.push(part.trim());
      }
      return result;
    }, []);
  };

  const splitValues = splitScramble(scramble);

  const pairedParts = [];
  if (cubeType == 'minx') {
    // Split the scramble string at each ' U ' or ' U''

    // Group the parts into pairs
    
    for (let i = 0; i < splitValues.length; i += 2) {
      pairedParts.push(splitValues.slice(i, i + 2).join(' '));
    }
  }

  const groupValues = (values) => {
    const grouped = [];
    for (let i = 0; i < values.length; i += 2) {
      if (i + 1 < values.length) {
        grouped.push([values[i] , values[i + 1]]);
      } else {
        grouped.push([values[i]]);
      }
    }
    return grouped;
  };

  const groupedValues = groupValues(pairedParts);


  // console.log(pairedParts)

  // for(let i = 0; i < pairedParts.length; i++){
  //   console.log(pairedParts[i])
  // }

  // console.log(scramble)

  return (
    <>

      {isMobile ? (
        <div
          className="top-touch"
          style={{
            height: `${(isTimer || inspRunningRef.current) ? '100vh' : '15%'}`,
            top: `${(isTimer || inspRunningRef.current) ? '0' : '15%'}`,
            zIndex: `${(isTimer || inspRunningRef.current) ? '999' : '- 1'}`,
          }}
          onTouchStart={touchDown}
          onTouchEnd={touchUp}
        ></div>
      ) : null}
      <div
        className="timer-section prevent-select"
        style={
          {
            // position: "relative",
            // top: `${isMobile && cubeType == 'minx' ? '17%' : "" }`,
            // top: `${isMobile ? cubeType == "Megaminx" ? '23%' : null : null}`,
          }
        }
        id="start-inspection"
      >
        <TextDiv
          theme={allStyles}
          ref={textRef}
          className={
            isMobile
              ? `timer-scramble-text${cubeType == "444" ? "-444" : ""}${cubeType == "777" ? "-777" : ""
              }${cubeType == "minx" ? "-minx" : ""}${cubeType == "555" ? "-555" : ""
              }${cubeType == "666" ? "-666" : ""}`
              : "timer-scramble-text"
          }
          style={{ fontSize: `${scrambleFontSize}px`, lineHeight: "25px", flexDirection: `${cubeType == 'minx' ? 'column' : 'row'}` }}
        >
          {cubeType === 'clock' 
          ? 
          checkScrString(scramble, scrambleStrings) 
          ? 
          scrString(scramble, scrambleStrings) === '' 
          ? scramble 
          : 
          removeSubstring(scramble, scrString(scramble, scrambleStrings)) 
          : scramble 
          
          : 
          cubeType === 'minx' && !isMobile ? 
          <>
          
          {groupedValues.map((group, index) => {
            return (
              <div key={index} className="minx-conatiner" >
                {group.map((value, index) => {
                  return (
                    <div key={index} >
                      {value}
                      {index === group.length - 1 ? <br /> : ' '}
                    </div>
                  )
                })}
              </div>
            )
          })}
{/* {groupedValues[0]}  */}
          </>
           : scramble
          }
        </TextDiv>
        <div className="scramble-icons">
          {lockIcon && lockScramble ? (
            <MdOutlineLock
              title="Lock"
              onClick={() => {
                LockScrambleInStorage();
              }}
              className={
                lockIcon && lockScramble
                  ? "lock"
                  : `${themeName === "light" ? "dropdown-color" : ""} lock-img`
              }
            />
          ) : (
            <MdLockOpen
              title="Lock"
              onClick={() => {
                LockScrambleInStorage();
              }}
              className={
                lockIcon && lockScramble
                  ? "lock"
                  : `${themeName === "light" ? "dropdown-color" : ""} lock-img`
              }
            />
          )}

          <LuCopy
            onClick={copyToClipboard}
            title="Copy"
            className={`${themeName === "light" ? "dropdown-color" : ""
              } lock-img`}
          />

          <LuRefreshCcw
            title="Refresh"
            onClick={() => {
              setRefresh(!refresh);
              if (!scrambleRefresh && lockScramble) {
                setScrambleRefresh(true);
                toast.error("Unlock the Scramble!", { autoClose: 1000, className: "copy-toast" });
                setTimeout(() => {
                  setScrambleRefresh(false);
                }, 2000);
              }
              if (!scrambleRefresh && !lockScramble) {
                setScrambleRefresh(true);
                toast.success("Scramble Refreshed!", { autoClose: 1000, className: "copy-toast" });
                setTimeout(() => {
                  setScrambleRefresh(false);
                }, 2000);
              }
            }}
            className={`${themeName === "light" ? "dropdown-color" : ""
              } lock-img`}
          />
        </div>

        <TextDiv
          theme={allStyles}
          onTouchStart={touchDown}
          onTouchEnd={touchUp}
          ref={timerDisplayRef}
          className={`timer`}
          style={{
            fontSize: `${TimerFontSize}px`,
          }}
          id="start-solving"
        >
          {ManualTime ? (
            <TimerInput
              theme={allStyles}
              type="number"
              value={timerInput}
              className="timer-input-manual"
              onChange={handleInputChange}
              onKeyDown={handleKeyPress}
              style={{
                color: `${themeName === "light" ? "rgba(51, 51, 51, 1)" : ""}`,
              }}
            />
          ) : (
            <>
              {inspRunningRef.current ? (
                <div
                  className={
                    "" +
                    (inspColor == "green" ? "green-text" : "") +
                    (inspColor == "red" ? "red-text" : "")
                  }
                >
                  {inspDnf
                    ? "DNF"
                    : inspCd >= 0 && inspCd <= 14
                      ? inspCd
                      : "+2"}
                </div>
              ) : (
                hideTimer == true && solveSubmit == false ?
                  <TextDiv
                    className="timer-solve-text"
                    theme={allStyles}>Solve</TextDiv> :
                  <>
                    {extractMinutes(displayTime)}
                    {extractSeconds(displayTime)}
                    {extractCentiseconds(displayTime)}
                  </>
              )}
            </>
          )}
        </TextDiv>

        <TextDiv
          theme={allStyles}
          onTouchStart={touchDown}
          onTouchEnd={touchUp}
          ref={timerInspectionRef}
          className={`timer hidden`}
          style={{
            fontSize: `${TimerFontSize}px`,
            marginTop: "1rem",
            padding: "2rem",
          }}
          id="start-solving"
        >
          <>
            <div
              className={
                "" +
                (inspColor == "green" ? "green-text" : "") +
                (inspColor == "red" ? "red-text" : "")
              }
            >
              {inspDnf ? "DNF" : inspCd >= 0 && inspCd <= 14 ? inspCd : "+2"}
            </div>
          </>
        </TextDiv>
      </div>
      {isMobile ? (
        <div
          className="bot-touch"
          onTouchStart={touchDown}
          onTouchEnd={touchUp}
        ></div>
      ) : null}
    </>
  );
};

export default TimerSection;
