import React, { useContext, useEffect, useState } from "react";
import "./TimerPage.scss";
import { ThemeContext } from "../../context";
import { MainTimerDiv } from "../../components/Timer/styledComponents";
import Focus from "../../assets/images/timer/focus.svg";
import TopBar from "../../components/Timer/Top Bar/TopBar";
import TimerSection from "../../components/Timer/Timer Section/TimerSection";
import BottomaBar from "../../components/Timer/Bottom Bar/BottomBar";
import { generateScramble } from "../../utils/functions/scrambleFunctions";
import { useIsMobile } from "../../utils/functions/CustomHooks";
import { ReserveContext } from "../../App";
import MainSolveModal from "../../components/Modals/SolveModal/MainSolveModal";
import MainDeleteSolveModal from "../../components/Modals/DeleteSolveModal/MainDeleteSolveModal";

const TimerPage = ({ focusMode, setFocusMode }) => {
  window.main();
  const [cubeType, setCubeType] = useState(localStorage.getItem("cubeType") || "222");
  const [disableCubeType, setDisableCubeType] = useState(false);
  const [load, setLoad] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [scramble, setScramble] = useState("Loading.....");
  const [svg, setSvg] = useState("load");
  const [sessionData, setSessionData] = useState({});
  //timer variable
  const [isTimer, setIsTimer] = useState(false);
  //create session box variable
  const [isOpen, setIsOpen] = useState(false);
  const [lockScramble, setLockScramble] = useState(false);
  const [lockIcon, setLockIcon] = useState(false);

  

  useEffect(() => {
    let check = localStorage.getItem("lockScramble");
    let checkCubeType = localStorage.getItem("lockCubeType");
    let svgType = localStorage.getItem('svg')
    let scr;
    if (check) {
      if (cubeType == checkCubeType) {
        setLockIcon(true);
        scr = check;
        // setCubeType(checkCubeType);
        // setDisableCubeType(true);
        setSvg(svgType);
      }
      else {
        setLockIcon(false)
        if (cubeType == "minx") {
          scr = generateScramble("mminx");
        } else {
          scr = generateScramble(cubeType);
        }
      }

    }
    else {
      if (cubeType == "minx") {
        scr = generateScramble("mminx");
      } else {
        scr = generateScramble(cubeType);
      }
    }
    setScramble(scr);
    setLoad(true);
  }, [cubeType, refresh]);
  const { allStyles } = useContext(ThemeContext);
  const handleCubeType = (type) => {
    setCubeType(type);
  };
  const isMobile = useIsMobile();
  const { isFocus,overlay,Setoverlay ,  hideTimer, setHideTimer,
    solveSubmit, setSolveSubmit,matrixDropdown} = useContext(ReserveContext)

    // if(hideTimer == true && solveSubmit == false){
    //   Setoverlay(true)
    // }
  return (
    <>
     {
        isMobile && (isOpen || matrixDropdown) && <div className="no-selector"></div>
      }
{/* <div className="timer-overlay"></div> */}
      <MainDeleteSolveModal />

      <MainSolveModal />

      <div theme={allStyles} className={`timer-main${isMobile ? '-mobile' : ""} ${isFocus ? 'timer-position' : ''} no-select`}>
        <TopBar
          sessionData={sessionData}
          setSessionData={setSessionData}
          Focus={Focus}
          allStyles={allStyles}
          cubeType={cubeType}
          handleCubeType={handleCubeType}
          setLoad={setLoad}
          disableCubeType={disableCubeType}
          setDisableCubeType={setDisableCubeType}
          focusMode={focusMode}
          setFocusMode={setFocusMode}
          isOpen={isOpen} 
          setIsOpen={setIsOpen}
          isTimer={isTimer}
        />

        <TimerSection
          scramble={scramble}
          setScramble={setScramble}
          allStyles={allStyles}
          setRefresh={setRefresh}
          refresh={refresh}
          cubeType={cubeType}
          disableCubeType={disableCubeType}
          setDisableCubeType={setDisableCubeType}
          svg={svg}
          setSvg={setSvg}
          focusMode={focusMode}
          setFocusMode={setFocusMode}
          sessionData={sessionData}
          setSessionData={setSessionData}
          isTimer={isTimer} setIsTimer={setIsTimer}
          isOpen={isOpen} setIsOpen={setIsOpen}
          lockScramble={lockScramble} setLockScramble={setLockScramble}
          lockIcon={lockIcon} setLockIcon={setLockIcon}
          overlay={overlay} 
          Setoverlay={Setoverlay}
        />


        <BottomaBar
          scramble={scramble}
          cubeType={cubeType}
          allStyles={allStyles}
          load={load}
          svg={svg}
          setSvg={setSvg}
          focusMode={focusMode}
          setFocusMode={setFocusMode}
          sessionData={sessionData}
          setSessionData={setSessionData}
          overlay={overlay} 
          Setoverlay={Setoverlay}
          isTimer={isTimer}
        />
      </div>
     
    </>
  );
};

export default TimerPage;
