import React, { useContext, useState } from 'react'
import { deleteSolve, updateSolve } from '../../../apis/solves';
import { ReserveContext } from '../../../App';
import { toast } from 'react-toastify';
import SolveModal from './SolveModal';
import { ThemeContext } from '../../../context';

const MainSolveModal = () => {

    const { allStyles } = useContext(ThemeContext);
    const updateReserve = useContext(ReserveContext).updateReserve;
    let cubeType = localStorage.getItem("cubeType") || "222";
    let sessions = useContext(ReserveContext).reserve.sessions
    var solvesData = useContext(ReserveContext).reserve.solves;
    let currentSession = localStorage.getItem('current_session');
    currentSession = JSON.parse(currentSession)
    let currentSessionIdRead = currentSession?.sessionId;
    const [currentSessionId, setCurrentSessionId] = useState(currentSessionIdRead ? currentSessionIdRead : sessions ? sessions[0] : '');
    let currentSolvesData = solvesData ? solvesData?.filter((item) => item?.sessionId === currentSessionId && item.cubeType === cubeType) : null
    const [solves, setSolves] = useState(currentSolvesData);

    const {
        setDeleteSolveModal,
        DeleteSolve,
        solveModal, setSolveModal,
        solveModalVlue, setsolveModalVlue,
        CurrentSolve,
        setSolveId
    } = useContext(ReserveContext)

    const handleDeleteSolve = async (solveId) => {
        try {
            await deleteSolve(solveId);
            setSolves((prevSolves) => prevSolves.filter((solve) => solve.solveId !== solveId));
            updateReserve((prevReserve) => ({
                ...prevReserve,
                solves: prevReserve.solves.filter((solve) => solve.solveId !== solveId),
            }));
            toast.success("Solve deleted successfully.",{autoClose:1000,className:'copy-toast'})
        } catch (error) {
            toast.error("Error deleting solve.",{autoClose:1000,className:'copy-toast'})
            console.error("Error deleting solve:", error);
        }
    };

    const handleUpdateSolveForPlus2 = async (solveToUpdate) => {
        try {
            const updatedSolve = { ...solveToUpdate, plus2Manual: !solveToUpdate.plus2Manual };
            setsolveModalVlue({ ...solveModalVlue, plus2Manual: !solveModalVlue.plus2Manual })
            await updateSolve(updatedSolve);
            setSolves((prevSolves) =>
                prevSolves.map((solve) =>
                    solve.solveId === updatedSolve.solveId ? updatedSolve : solve
                )
            );
            updateReserve((prevReserve) => ({
                ...prevReserve,
                solves: prevReserve.solves.map((solve) =>
                    solve.solveId === updatedSolve.solveId ? updatedSolve : solve
                ),
            }));
        } catch (error) {
            console.error("Error updating solve:", error);
        }
    };

    const handleUpdateSolveDNF = async (solveToUpdate) => {
        try {
            const updatedSolve = { ...solveToUpdate, dnfManual: !solveToUpdate.dnfManual };
            setsolveModalVlue({ ...solveModalVlue, dnfManual: !solveModalVlue.dnfManual })

            await updateSolve(updatedSolve);
            setSolves((prevSolves) =>
                prevSolves.map((solve) =>
                    solve.solveId === updatedSolve.solveId ? updatedSolve : solve
                )
            );
            updateReserve((prevReserve) => ({
                ...prevReserve,
                solves: prevReserve.solves.map((solve) =>
                    solve.solveId === updatedSolve.solveId ? updatedSolve : solve
                ),
            }));

        }
        catch (error) {
            console.error("Error updating solve:", error);
        }
    }
    return (
        <>
            {solveModal && <SolveModal
                setSolveModal={setSolveModal}
                allStyles={allStyles}
                solveModalVlue={solveModalVlue}
                handleUpdateSolveForPlus2={handleUpdateSolveForPlus2}
                handleUpdateSolveDNF={handleUpdateSolveDNF}
                CurrentSolve={CurrentSolve}
                setDeleteSolveModal={setDeleteSolveModal}
                setSolveId={setSolveId}
                handleDeleteSolve={handleDeleteSolve}
                DeleteSolve={DeleteSolve}
            />}
        </>
    )
}

export default MainSolveModal