import React, { useContext, useEffect, useState } from 'react'
import './Profile.scss'
import { Heading, InputTextColor, FocusBox, Form, TextColor } from './StyledComponent'
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import {
    CitySelect,
    CountrySelect,
    StateSelect,
    GetCountries,
    GetState,
    GetCity,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import LogoutModal from './LogoutModal';
import { FaCalendarAlt } from "react-icons/fa";
import { ThemeContext } from '../../context';
import axios from 'axios';
import { toast } from 'react-toastify';
import { ReserveContext } from '../../App';
import ProfileMobileModal from '../Modals/ProfileModal/ProfileModal';
import animation from "../../assets/images/animation_3.gif"
const ProfileComponent = ({ allStyles }) => {

    const uid = localStorage.getItem('current_session')?.uid;
    const [userData, setuserData] = useState('')
    const [name, setname] = useState("")
    const [gender, setGender] = useState("")
    const [wcaid, setWcaid] = useState("")
    const [DOB, setDOB] = useState("")
    const [country, setcountry] = useState("")
    const [state, setstate] = useState("")
    const [city, setcity] = useState('')
    const [countryid, setcountryid] = useState("")
    const [stateid, setstateid] = useState("")
    const [cityid, setcityid] = useState('')
    const [mobile, setmobile] = useState('')
    const [logoutModal, setLogoutModal] = useState(false)
    const { themeName } = useContext(ThemeContext)
    const { ProfileMobile, setProfileMobile } = useContext(ReserveContext)
    const [formSubmitted, setFormSubmitted] = useState(false);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get('https://upcube.el.r.appspot.com/api/v1/auth/user', uid, {
                    headers: {
                        'Content-Type': 'application/json',
                        // Add any additional headers if required
                    }
                });
                // console.log(response)
                localStorage.setItem("profile", false)

                if (!response) {
                    toast.error('Failed to fetch user data .');
                } else {
                    // console.log(response.data)
                    setuserData(response.data.user)
                    setname(response.data.user.name)
                    setDOB(new Date(response.data.user.dob))
                    // setcountry(response.data.user.country)
                    // setstate(response.data.user.state)
                    setGender(response.data.user.gender)
                    setWcaid(response.data.user.social.wca)
                    setmobile(response.data.user.mobile)
                    // setcity(response.data.user.city)
                    const countryList = await GetCountries()
                    const selectedCountry = await countryList.filter((item) => item.name === (response.data.user.country ? response.data.user.country : "India"))[0]
                    setcountry(selectedCountry)
                    setcountryid(selectedCountry.id)
                    const stateList = await GetState(selectedCountry.id)
                    const selectedState = await stateList.filter((item) => item.name === (response.data.user.state ? response.data.user.state : "Madhya Pradesh"))[0]

                    setstate(selectedState)
                    setstateid(selectedState.id)

                    const CityList = await GetCity(selectedCountry.id, selectedState.id)
                    const selectedCity = await CityList.filter((item) => item.name === (response.data.user.city ? response.data.user.city : "Bhopal"))[0]
                    setcity(selectedCity)
                    setcityid(selectedCity.id)

                }
            } catch (error) {
                console.log('error', error)
            }
        }
        fetchUserData()


    }, [])


    const handleChange = (e) => {
        if (e.target.name === 'name') {
            setname(e.target.value)
        } else if (e.target.name === 'wcaid') {
            setWcaid(e.target.value)
        } else if (e.target.name === 'mobile') {
            setmobile(e.target.value)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!state.name) {
            if (!formSubmitted) {
                setFormSubmitted(true);
                setTimeout(() => {
                    setFormSubmitted(false);
                }, 3000); // Reset formSubmitted after 3 seconds
                toast.error('Please select proper State & City');

            }
            return;
        }
        if (!city.name) {
            if (!formSubmitted) {
                setFormSubmitted(true);
                setTimeout(() => {
                    setFormSubmitted(false);
                }, 3000); // Reset formSubmitted after 3 seconds
                toast.error("Please select proper City");
            }
            return;
        }
        const formData = {
            ...userData,
            gender: gender,
            name: name,
            dob: DOB,
            country: country.name,
            state: state.name,
            city: city.name,
            mobile: mobile,
            social: {
                ...userData.social,
                wca: wcaid
            }
        };

        try {
            const response = await axios.put('https://upcube.el.r.appspot.com/api/v1/auth/update', formData, {
                headers: {
                    'Content-Type': 'application/json',
                    // Add any additional headers if required
                }
            });
            if (!response) {
                toast.error('Failed to Save Changes.');
            }
            if (!response) {
                toast.error('Failed to save changes.');
            } else {
                if (!formSubmitted) {
                    setFormSubmitted(true);
                    setTimeout(() => {
                        setFormSubmitted(false);
                    }, 3000); // Reset formSubmitted after 3 seconds
                    toast.success('Saved changes successfully');
                }
            }


        } catch (error) {
            toast.error('Failed to Save Changes.');
            console.error('Error submitting form:', error);
            // Handle error (e.g., show error message)
        }
    }

    function formatDate(dateString) {
        const date = new Date(dateString);

        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    }




    return (
        <>
            {
                userData ? (<div className='profile-container'>

                    {ProfileMobile && <ProfileMobileModal />}

                    {logoutModal && <LogoutModal allStyles={allStyles} setLogoutModal={setLogoutModal} />}

                    <div className='profile-header'>

                        {/* stats heading */}

                        <Heading theme={allStyles} className='profile-heading'>
                            Profile
                        </Heading>

                        {/* stats buttons */}

                        <div className='profile-btn'>

                            <FocusBox theme={allStyles} className='focus-button' onClick={() => { setLogoutModal(true) }}>
                                Log Out
                            </FocusBox>

                        </div>

                    </div>

                    <Form theme={allStyles} className='profile-form-padding'>
                        <div className='profile-form'>

                            {/* desktop-form */}
                            <form onSubmit={handleSubmit} className='desktop-profile-form'>

                                <div className='profile-form-content'>

                                    {/* Name */}
                                    <div className='form-div'>

                                        <InputTextColor theme={allStyles} htmlFor="name">Name</InputTextColor>

                                        <input type="text" value={name ? name : " "} onChange={handleChange} name='name' id='name' required
                                        // style={{ background: `${themeName === 'light' ? 'rgba(222, 222, 222, 1)' : ''}` }}
                                        />

                                    </div>

                                    {/* Gender */}
                                    <div className='form-div'>

                                        <InputTextColor theme={allStyles} htmlFor="gender">Gender</InputTextColor>

                                        <select value={gender ? gender : "other"} onChange={(e) => setGender(e.target.value)} name='gender' id='username' required
                                        // style={{ background: `${themeName === 'light' ? 'rgba(222, 222, 222, 1)' : ''}` }}
                                        >
                                            <option value="">Select</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                            <option value="other">Other</option>
                                        </select>
                                    </div>

                                    {/* WCA id */}
                                    <div className='form-div'>

                                        <InputTextColor theme={allStyles} htmlFor="wcaid">WCA id</InputTextColor>

                                        <input type="text" value={wcaid ? wcaid : " "} onChange={handleChange} name='wcaid' id='wcaid'
                                        //  style={{ background: `${themeName === 'light' ? 'rgba(222, 222, 222, 1)' : ''}` }} 
                                        />

                                    </div>

                                    {/* Date */}
                                    <div className='form-div' style={{ position: 'relative' }}>

                                        <InputTextColor theme={allStyles} htmlFor="dob">Date of Birth</InputTextColor>
                                        <FaCalendarAlt className='info-profile-calendar' />

                                        <Datetime
                                            value={DOB ? DOB : "01-01-1999"}
                                            onChange={(date) => setDOB(date)}
                                            timeFormat={false}
                                            dateFormat="DD-MM-YYYY"
                                            className={`profile-date-picker${themeName === 'light' ? '' : ''}`}
                                        />
                                    </div>

                                    {/* country */}
                                    <div className='form-div'>

                                        <InputTextColor theme={allStyles} htmlFor="country">Country</InputTextColor>

                                        <CountrySelect
                                            value={country ? country : "India"}
                                            defaultValue={country}
                                            onChange={(e) => { setcountry(e); setcountryid(e.id); setstate(''); setcity('') }}
                                            className="profile-country-dropdown"
                                            required
                                        // style={{ background: `rgb(205 196 196 / 55%)` }}
                                        />
                                    </div>

                                    {/* state */}
                                    <div className='form-div '>

                                        <InputTextColor theme={allStyles} htmlFor="state">State</InputTextColor>

                                        <StateSelect
                                            value={state ? state : "Madhya Pradesh"}
                                            defaultValue={state}
                                            countryid={countryid}
                                            placeHolder='State'
                                            onChange={(e) => { setstate(e); setstateid(e.id); setcity('') }}
                                            className="profile-state-dropdown"
                                            required
                                        // style={{ background: `rgb(205 196 196 / 55%)` }}
                                        />

                                    </div>

                                    {/* City */}
                                    <div className='form-div'>

                                        <InputTextColor theme={allStyles} htmlFor="city">City</InputTextColor>

                                        <CitySelect
                                            value={city ? city : "Bhopal"}
                                            defaultValue={city}
                                            countryid={countryid}
                                            stateid={stateid}
                                            placeHolder="City"
                                            defaultOptionLabel=" "
                                            onChange={(e) => { setcity(e); setcityid(e.id) }}
                                            className="profile-state-dropdown"
                                            required
                                        // style={{ background: `${themeName === 'light' ? 'rgba(222, 222, 222, 1)' : ''}` }}
                                        />

                                    </div>

                                    {/* Mobile */}
                                    <div className='form-div'>

                                        <InputTextColor theme={allStyles} htmlFor="mobile">Mobile Number</InputTextColor>

                                        <input type="number" value={mobile ? mobile : ""} onChange={handleChange} name='mobile' id='mobile' required
                                        // style={{ background: `${themeName === 'light' ? 'rgba(222, 222, 222, 1)' : ''}` }}
                                        />

                                    </div>

                                </div>

                                <div className='profile-form-btn'>

                                    <FocusBox
                                        theme={allStyles}
                                        className='focus-button focus-btn-desktop'
                                        type='submit'
                                        style={{
                                            // background: `${themeName === 'light' ? 'rgba(222, 222, 222, 1)' : ''}`,
                                            cursor: formSubmitted && 'not-allowed',
                                        }}
                                        disabled={formSubmitted}
                                    >
                                        Update Info
                                    </FocusBox>


                                </div>


                            </form>

                            {/* Mobile display data */}

                            <div className='profile-form-content-mobile'>

                                <TextColor theme={allStyles} className='profile-data-mobile'>
                                    Name : {name ? name : " "}
                                </TextColor>

                                <TextColor theme={allStyles} className='profile-data-mobile'>
                                    Gender : {gender ? gender : ""}
                                </TextColor>

                                <TextColor theme={allStyles} className='profile-data-mobile'>
                                    WCA id : {wcaid ? wcaid : ""}
                                </TextColor>

                                <TextColor theme={allStyles} className='profile-data-mobile'>
                                    Date of birth : {DOB ? formatDate(DOB) : "dd-mm-yyyy"}
                                </TextColor>

                                <TextColor theme={allStyles} className='profile-data-mobile'>
                                    Country : {country ? country.name : "India"}
                                </TextColor>

                                <TextColor theme={allStyles} className='profile-data-mobile'>
                                    State : {state ? state.name : "Madhya Pradesh"}
                                </TextColor>

                                <TextColor theme={allStyles} className='profile-data-mobile'>
                                    City : {city ? city.name : "Bhopal"}
                                </TextColor>

                                <TextColor theme={allStyles} className='profile-data-mobile'>
                                    Mobile Number : {mobile ? mobile : ""}
                                </TextColor>

                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '1rem' }}>
                                    <FocusBox theme={allStyles} className='focus-button focus-btn-mobile' type='submit'
                                        // style={{ background: `${themeName === 'light' ? 'rgba(222, 222, 222, 1)' : ''}` }}
                                        onClick={() => { setProfileMobile(!ProfileMobile) }}>
                                        Update Info
                                    </FocusBox>
                                </div>

                            </div>

                        </div>
                    </Form>

                </div>) : (<div className='loader-mobile' style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100vw',
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                }}>
                    <img src={animation} alt="Loading.."  />
                </div>)
            }</>
    )
}

export default ProfileComponent