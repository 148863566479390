import React, { useContext, useEffect, useState } from "react";
import { Box, TextDiv, Background } from "../styledComponents";
import { ReserveContext } from "../../../App";
import { deleteSolve, submitSolve, updateSolve } from "../../../apis/solves";
import {
  extractCentiseconds,
  extractFormatHours,
  extractHours,
  extractMinutes,
  extractSeconds,
  extractSecondsFormat,
  extractStatsMinutes,
  extractStatsSeconds,
} from "../Timer Section/TimerLogic";
import "./BottomBar.scss";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteModal from "../../Session/DeleteModal";
import { toast } from "react-toastify";
import { ThemeContext } from "../../../context";

const SolvesBox = ({ allStyles, NoSolves, cubeType, sessionData }) => {

  const { 
    isFocus, 
    setDeleteSolveModal, 
    DeleteSolve,
    setSolveModal, 
    setCurrentSolve,
    solveModalVlue, setsolveModalVlue,
   setSolveId
  } = useContext(ReserveContext)

  var solvesData = useContext(ReserveContext)?.reserve.solves;
  const { themeName } = useContext(ThemeContext)

  let currentSessionId = sessionData.sessionId;

  let currentSolvesData = solvesData ? solvesData?.filter((item) => item?.sessionId === currentSessionId && item?.cubeType === cubeType) : null
  const [solves, setSolves] = useState(currentSolvesData);

  // const [solveId, setSolveId] = useState('')

  useEffect(() => {
    setSolves(currentSolvesData?.reverse())
  }, [solvesData, currentSessionId, cubeType])

  const filteredSolves = solves ? solves?.filter((solve) => solve?.cubeType === cubeType) : null;

  const updateReserve = useContext(ReserveContext).updateReserve;

  const handleDeleteSolve = async (solveId) => {
    try {
      await deleteSolve(solveId);
      setSolves((prevSolves) => prevSolves.filter((solve) => solve.solveId !== solveId));
      updateReserve((prevReserve) => ({
        ...prevReserve,
        solves: prevReserve.solves.filter((solve) => solve.solveId !== solveId),
      }));
      toast.success("Solve deleted successfully.",{autoClose:1000,className:'copy-toast'})
    } catch (error) {

      toast.error("Error deleting solve.",{autoClose:1000,className:'copy-toast'})
      console.error("Error deleting solve:", error);
    }
  };

  const handleUpdateSolveForPlus2 = async (solveToUpdate) => {
    try {
      const updatedSolve = { ...solveToUpdate, plus2Manual: !solveToUpdate.plus2Manual };
      setsolveModalVlue({ ...solveModalVlue, plus2Manual: !solveModalVlue.plus2Manual })
      await updateSolve(updatedSolve);
      setSolves((prevSolves) =>
        prevSolves.map((solve) =>
          solve.solveId === updatedSolve.solveId ? updatedSolve : solve
        )
      );
      updateReserve((prevReserve) => ({
        ...prevReserve,
        solves: prevReserve.solves.map((solve) =>
          solve.solveId === updatedSolve.solveId ? updatedSolve : solve
        ),
      }));
    } catch (error) {
      console.error("Error updating solve:", error);
    }
  };

  const handleUpdateSolveDNF = async (solveToUpdate) => {
    try {
      const updatedSolve = { ...solveToUpdate, dnfManual: !solveToUpdate.dnfManual };
      setsolveModalVlue({ ...solveModalVlue, dnfManual: !solveModalVlue.dnfManual })

      await updateSolve(updatedSolve);
      setSolves((prevSolves) =>
        prevSolves.map((solve) =>
          solve.solveId === updatedSolve.solveId ? updatedSolve : solve
        )
      );
      updateReserve((prevReserve) => ({
        ...prevReserve,
        solves: prevReserve.solves.map((solve) =>
          solve.solveId === updatedSolve.solveId ? updatedSolve : solve
        ),
      }));

    }
    catch (error) {
      console.error("Error updating solve:", error);
    }
  }
console.log(solves)
  return (<>
    
    <Box theme={allStyles} className={`timer-box timer-box-1 ${isFocus ? 'hidden' : ''}`}
    id={`${themeName ==='light' ?'style-1' :""}`}
    
    >

      {!filteredSolves || solves.length == 0 ? (
        <Background theme={allStyles} className="session-no-solves-div">
          <img src={NoSolves} alt="NoSolves" />
        </Background>
      ) : (
        <>
          {solves.map((solve, index) => {
            return (
              <React.Fragment key={index + 1}>
                <div className={`timer-solve ${isFocus ? 'hidden' : ''}`}
                
                >
                  <div className="timer-solve-left">
                    <TextDiv theme={allStyles} className="solve-col sno">
                      {solves.length - index}.
                    </TextDiv>

                    <div className="solve-col solve-value" onClick={() => {
                      setSolveModal(true)
                      setCurrentSolve(solve)
                      setsolveModalVlue({
                        scramble: solve.scramble,
                        date: solve.dateTime,
                        cubetype: solve.cubeType,
                        plus2Auto: solve.plus2Auto,
                        plus2Manual: solve.plus2Manual,
                        dnfAuto: solve.dnfAuto,
                        dnfManual: solve.dnfManual,
                        solveTime: solve.time
                      })
                    }}>
                      {/* {
                        TimerFormat === 'ss.XX' &&
                        <>
                          {solve.dnfAuto || solve.dnfManual ? "DNF" : (solve.plus2Auto || solve.plus2Manual ? `${extractSecondsFormat(solve.time + 2000)}` : extractSecondsFormat(solve.time))}
                          {solve.dnfAuto || solve.dnfManual ? null : extractCentiseconds(solve.time)}
                        </>
                      } */}
                      {solve.dnfAuto || solve.dnfManual ? null : extractMinutes(solve.time)}
                      {solve.dnfAuto || solve.dnfManual ? "DNF" : (solve.plus2Auto || solve.plus2Manual ? `${extractSeconds(solve.time + 2000)}` : extractSeconds(solve.time))}
                      {solve.dnfAuto || solve.dnfManual ? null : extractCentiseconds(solve.time)}
                    </div>
                  </div>
                  <div className="timer-solve-right">
                    <TextDiv
                      theme={allStyles}
                      className={`solve-col timer-plus2 ${solve.plus2Auto || solve.plus2Manual
                        ? "plus2-enable"
                        : null
                        }`}
                      onClick={() => {
                        handleUpdateSolveForPlus2(solve);
                      }}
                    >
                      +2
                    </TextDiv>
                    <TextDiv theme={allStyles} className={`solve-col timer-dnf ${solve.dnfManual || solve.dnfAuto ? "plus2DNF-enable" : null}`} onClick={() => {
                      handleUpdateSolveDNF(solve);
                    }}
                    >
                      DNF
                    </TextDiv>
                    <CancelIcon
                      className="solve-col"
                      style={{ fontSize: '1.25rem' }}
                      onClick={() => {
                        if (DeleteSolve) {
                          setDeleteSolveModal(true)
                          setSolveId(solve?.solveId)
                        } else {
                          handleDeleteSolve(solve?.solveId)
                        }
                      }}
                      // onClick={() => {
                      //   handleDeleteSolve(solve.solveId);
                      // }} 
                      sx={{ color: '#d30c0c' }}
                    />
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </>
      )}
    </Box>
  </>
  );
};

export default SolvesBox;
