import React, { useContext, useState } from 'react'
import { ReserveContext } from '../../../App'
import { ThemeContext } from '../../../context';
import { deleteSolve } from '../../../apis/solves';
import { toast } from 'react-toastify';
import DeleteModal from './DeleteModal';

const MainDeleteSolveModal = () => {
    const { allStyles } = useContext(ThemeContext);
    const updateReserve = useContext(ReserveContext).updateReserve;
    let cubeType = localStorage.getItem("cubeType") || "222";
    let sessions = useContext(ReserveContext).reserve.sessions
    var solvesData = useContext(ReserveContext).reserve.solves;
    let currentSession = localStorage.getItem('current_session');
    currentSession = JSON.parse(currentSession)
    let currentSessionIdRead = currentSession?.sessionId;
    const [currentSessionId, setCurrentSessionId] = useState(currentSessionIdRead ? currentSessionIdRead : sessions ? sessions[0] : '');
    let currentSolvesData = solvesData ? solvesData?.filter((item) => item?.sessionId === currentSessionId && item.cubeType === cubeType) : null
    const [solves, setSolves] = useState(currentSolvesData);
    const {
        setDeleteSolveModal, DeleteSolveModal,
        solveId,
    } = useContext(ReserveContext)

    const handleDeleteSolve = async (solveId) => {
        try {
            await deleteSolve(solveId);
            setSolves((prevSolves) => prevSolves.filter((solve) => solve.solveId !== solveId));
            updateReserve((prevReserve) => ({
                ...prevReserve,
                solves: prevReserve.solves.filter((solve) => solve.solveId !== solveId),
            }));
            toast.success("Solve deleted successfully.",{autoClose:1000,className:'copy-toast'})
        } catch (error) {
            toast.error("Error deleting solve.",{autoClose:1000,className:'copy-toast'})
            console.error("Error deleting solve:", error);
        }
    };

    return (
        <>
            {
                DeleteSolveModal &&
                <DeleteModal
                    allStyles={allStyles}
                    setDeleteSolveModal={setDeleteSolveModal}
                    handleDeleteSolve={handleDeleteSolve}
                    id={solveId}
                />
            }

        </>
    )
}

export default MainDeleteSolveModal