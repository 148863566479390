import React, { useState, useEffect, useContext, useRef } from "react";
import "./BottomBar.scss";
import {
  Box,
  OptionDiv,
  TextDiv,
  ShowFooterDiv,
  ButtonInactive,
  SelectDivMob,
} from "../styledComponents";
import NoSolves from "../../../assets/images/timer/nosolves.png";
import Svg from "../../cube/Svg";
import SolvesBox from "./SolvesBox";
import { ReserveContext } from "../../../App";
import { PrepareDataForStats } from "../../../utils/functions/operations.ts";
import ScrambleBox from "./ScrambleBox";
import StatsBox from "./StatsBox";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useIsMobile } from "../../../utils/functions/CustomHooks";
import { light } from "@mui/material/styles/createPalette";
import { ThemeContext } from "../../../context";
import { SelectDiv } from "../../Global/styledComponent.js";

const BottomBar = ({
  allStyles,
  scramble,
  cubeType,
  load,
  svg,
  setSvg,
  sessionData,
  setSessionData,
  overlay,
  Setoverlay,
  isTimer,
}) => {
  var solves = useContext(ReserveContext)?.reserve?.solves;
  const {
    isFocus,
    showFooter,
    setShowFooter,
    inspRunningRef,
    footerBlip,
    setFooterBlip,
  } = useContext(ReserveContext);
  let currentSessionId = sessionData?.sessionId;
  solves = solves
    ? solves.filter((item) => item?.sessionId === currentSessionId)
    : null;
  var stats = PrepareDataForStats(solves);
  const [select, setSelect] = useState(false);
  const [selectOption, setSelectOption] = useState(
    localStorage.getItem("option") || "solves"
  );
  const { themeName } = useContext(ThemeContext);
  const [matrixDropdown, setmatrixDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const handleSelectOption = (option) => {
    setSelectOption(option);
    localStorage.setItem("option", option);
  };

  const returnTextCubeType = (input) => {
    switch (input) {
      case "222":
        return "two";
      case "333":
        return "three";
      case "333OH":
        return "threeOH";
      case "333BF":
        return "threeBF";
      case "444":
        return "four";
      case "444BF":
        return "fourBF";
      case "555":
        return "five";
      case "555BF":
        return "fiveBF";
      case "666":
        return "six";
      case "777":
        return "seven";
      case "mminx":
        return "megaminx";
      default:
        return input;
    }
  };

  const isMobile = useIsMobile();

  useEffect(() => {
    switch (cubeType) {
      case "pyra":
      case "sq1":
      case "clock":
      case "skewb":
      case "mminx":
      case "minx":
        setSvg("svg");
        break;
      case "222":
      case "333":
      case "333OH":
      case "333BF":
      case "444":
      case "444BF":
      case "555":
      case "555BF":
      case "666":
      case "777":
        setSvg("cube");
      default:
        break;
    }
  }, [cubeType, solves]);

  useEffect(() => {
    // Handle clicks outside the sidebar
    const handleOutsideClick = (e) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target) &&
        e.target.id !== "select-cubetype"
      ) {
        setmatrixDropdown(false);
      }
    };

    // Attach the event listener
    document.addEventListener("mousedown", handleOutsideClick);

    // Clean up the event listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [matrixDropdown]);

  return (
    <div className={`timer-bottom-bar ${isFocus ? "hidden" : ""}`}>
      <div className="disappear">
        <SolvesBox
          allStyles={allStyles}
          NoSolves={NoSolves}
          cubeType={cubeType}
          sessionData={sessionData}
        />
      </div>
      <div
        className={`appear ${isFocus ? "hidden" : ""}`}
        style={{
          width: `${isMobile ? (showFooter ? "95vw" : "85vw") : ""}`,
          alignItems: `${isMobile ? (showFooter ? "center" : "end") : ""}`,
        }}
      >
        {isMobile ? (
          <>
            <ShowFooterDiv
              theme={allStyles}
              style={{ display: `${!showFooter ? "" : "none"}` }}
              onClick={() => {
                if (!isTimer && !inspRunningRef.current) {
                  setShowFooter(!showFooter);
                  Setoverlay(!overlay);
                }
              }}
              className="show-footer"
            >
              <TextDiv theme={allStyles} className="show-footer-text">
                <KeyboardArrowUpIcon
                  className="KeyboardArrowUpIcon"
                  sx={{ color: themeName === "light" ? "black" : "#ffffff" }}
                />
              </TextDiv>
            </ShowFooterDiv>
          </>
        ) : (
          <></>
        )}

        <Box
          style={{ display: `${isMobile ? (!showFooter ? "none" : "") : ""}` }}
          theme={allStyles}
          className={`timer-box timer-box-2 overlay-css `}
          id={`${themeName === "light" ? "style-1" : ""}`}
          onMouseEnter={() => {
            if (!isMobile) {
              setSelect(true);
            }
          }}
          onMouseLeave={() => {
            if (!isMobile) {
              setSelect(false);
            }
          }}
        >
          {isMobile && (
            <ButtonInactive
              theme={allStyles}
              className="timer-box-select"
              onClick={() => {
                setmatrixDropdown(!matrixDropdown);
              }}
            >
              {selectOption}

              {/* <img src={dropdown} alt="" /> */}
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.6918 3.81886C14.4945 3.63604 14.2268 3.53333 13.9477 3.53333C13.6686 3.53333 13.401 3.63604 13.2036 3.81886L7.99365 8.64608L2.78371 3.81886C2.5852 3.64122 2.31933 3.54292 2.04337 3.54515C1.7674 3.54737 1.50342 3.64993 1.30827 3.83074C1.11313 4.01155 1.00244 4.25614 1.00004 4.51183C0.997642 4.76752 1.10373 5.01386 1.29545 5.19779L7.24952 10.7145C7.44689 10.8973 7.71456 11 7.99365 11C8.27273 11 8.5404 10.8973 8.73777 10.7145L14.6918 5.19778C14.8892 5.01491 15 4.76691 15 4.50832C15 4.24973 14.8892 4.00173 14.6918 3.81886Z"
                  fill={`${themeName === "light" ? "black" : "white"}`}
                />
              </svg>
              {matrixDropdown && (
                <ButtonInactive
                  theme={allStyles}
                  className="matrix-cubetype"
                  style={{
                    display: `${isMobile ? "" : "none"}`,
                    height: "fit-content",
                  }}
                  ref={dropdownRef}
                >
                  <SelectDiv
                    theme={allStyles}
                    className={`dropdown-matrix-cubetype ${
                      cubeType === selectOption ? "selected" : ""
                    }`}
                    onClick={() => {
                      handleSelectOption("stats");
                    }}
                  >
                    {"Stats"}

                    {selectOption === "stats" && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="-49 141 512 512"
                        width="55"
                        height="55"
                        aria-hidden="true"
                        className="dropdown-tick"
                        fill={themeName === "light" ? "#00000" : "#FFFFFF"}
                      >
                        <path d="M127 552.25a24.92 24.92 0 0 1-17.678-7.322l-101-101c-9.763-9.763-9.763-25.593 0-35.355 9.764-9.764 25.592-9.764 35.355 0l83.339 83.34 243.323-242.857c9.771-9.754 25.602-9.74 35.355.033 9.754 9.772 9.739 25.602-.033 35.355l-261 260.5A24.92 24.92 0 0 1 127 552.25"></path>
                      </svg>
                    )}
                  </SelectDiv>
                  <SelectDiv
                    theme={allStyles}
                    className={`dropdown-matrix-cubetype ${
                      cubeType === selectOption ? "selected" : ""
                    }`}
                    onClick={() => {
                      handleSelectOption("scramble");
                    }}
                  >
                    {"Scramble"}

                    {selectOption === "scramble" && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="-49 141 512 512"
                        width="55"
                        height="55"
                        aria-hidden="true"
                        className="dropdown-tick"
                        fill={themeName === "light" ? "#00000" : "#FFFFFF"}
                      >
                        <path d="M127 552.25a24.92 24.92 0 0 1-17.678-7.322l-101-101c-9.763-9.763-9.763-25.593 0-35.355 9.764-9.764 25.592-9.764 35.355 0l83.339 83.34 243.323-242.857c9.771-9.754 25.602-9.74 35.355.033 9.754 9.772 9.739 25.602-.033 35.355l-261 260.5A24.92 24.92 0 0 1 127 552.25"></path>
                      </svg>
                    )}
                  </SelectDiv>
                  <SelectDiv
                    theme={allStyles}
                    className={`dropdown-matrix-cubetype ${
                      cubeType === selectOption ? "selected" : ""
                    }`}
                    onClick={() => {
                      handleSelectOption("solves");
                    }}
                  >
                    {"Solves"}

                    {selectOption === "solves" && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="-49 141 512 512"
                        width="55"
                        height="55"
                        aria-hidden="true"
                        className="dropdown-tick"
                        fill={themeName === "light" ? "#00000" : "#FFFFFF"}
                      >
                        <path d="M127 552.25a24.92 24.92 0 0 1-17.678-7.322l-101-101c-9.763-9.763-9.763-25.593 0-35.355 9.764-9.764 25.592-9.764 35.355 0l83.339 83.34 243.323-242.857c9.771-9.754 25.602-9.74 35.355.033 9.754 9.772 9.739 25.602-.033 35.355l-261 260.5A24.92 24.92 0 0 1 127 552.25"></path>
                      </svg>
                    )}
                  </SelectDiv>
                  {/* <SelectDivMob
                        // className="timer-box-select"
                        theme={allStyles}
                        value={selectOption}
                        onChange={(event) => {
                          handleSelectOption(event.target.value);
                        }}
                        style={{ border: 'none', outline: 'none' }}
                      >
                        <OptionDiv theme={allStyles} value="stats">
                          Stats
                        </OptionDiv>
                        <OptionDiv theme={allStyles} value="scramble">
                          Scramble
                        </OptionDiv>
                        <OptionDiv theme={allStyles} value="solves">
                          Solves
                        </OptionDiv> */}
                  {/* <OptionDiv theme={allStyles} value="lastSolves">
                        Last Solves
                      </OptionDiv> */}

                  {/* </SelectDivMob> */}
                </ButtonInactive>
              )}
            </ButtonInactive>
          )}

          {isMobile ? (
            <ButtonInactive
              onClick={() => {
                if (isMobile) {
                  setFooterBlip(false);
                  setShowFooter(!showFooter);
                  Setoverlay(!overlay);
                }
              }}
              style={{
                display: `${isMobile ? (!showFooter ? "none" : "") : ""}`,
              }}
              className="hide-footer-mobile"
              theme={allStyles}
            >
              <KeyboardArrowUpIcon
                className="KeyboardArrowDownIcon"
                style={{ transform: `${!showFooter ? "" : "rotate(180deg)"}` }}
                sx={{ color: themeName === "light" ? "black" : "#ffffff" }}
              />
            </ButtonInactive>
          ) : null}

          {isMobile &&
            (() => {
              switch (selectOption) {
                case "stats":
                  if (!stats[returnTextCubeType(cubeType)]) {
                    return <TextDiv theme={allStyles}>No Stats Yet...</TextDiv>;
                  }
                  return (
                    <StatsBox
                      allStyles={allStyles}
                      stats={stats}
                      solves={solves}
                      returnTextCubeType={returnTextCubeType}
                      cubeType={cubeType}
                    />
                  );
                case "scramble":
                  return (
                    <>
                      <ScrambleBox
                        svg={svg}
                        Svg={Svg}
                        cubeType={cubeType}
                        scramble={scramble}
                        allStyles={allStyles}
                        load={load}
                      />
                    </>
                  );
                case "solves":
                  return (
                    <>
                      <SolvesBox
                        allStyles={allStyles}
                        NoSolves={NoSolves}
                        cubeType={cubeType}
                        sessionData={sessionData}
                      />
                    </>
                  );
                case "lastSolves":
                  return (
                    <>
                      <TextDiv theme={allStyles}>Last Solves</TextDiv>
                    </>
                  );
                default:
                  return null;
              }
            })()}

          <div
            style={{
              display: `${isMobile ? "none" : "flex"}`,
              gap: "0.5rem",
              height: "38vh",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <StatsBox
              allStyles={allStyles}
              stats={stats}
              solves={solves}
              returnTextCubeType={returnTextCubeType}
              cubeType={cubeType}
              style={{ display: `${isMobile ? "none" : ""}` }}
            />
          </div>
        </Box>
      </div>

      <div className="disappear">
        <ScrambleBox
          svg={svg}
          Svg={Svg}
          cubeType={cubeType}
          scramble={scramble}
          allStyles={allStyles}
          load={load}
        />
      </div>
    </div>
  );
};

export default BottomBar;
